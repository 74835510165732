var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"9aedcd43a1e7c5c27a21bd9a4bf5a435735e9ece"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { init } from '@sentry/browser'

const SENTRY_DSN =
  process.env.SENTRY_DSN ||
  process.env.NEXT_PUBLIC_SENTRY_DSN ||
  'https://f93b54bb69a6240b33adc02ba747be0a@o4506915934240768.ingest.us.sentry.io/4506934057107456'

const SENTRY_ENV = process.env.SENTRY_ENVIRONMENT

init({
  dsn: SENTRY_DSN,
  environment: process.env.SENTRY_ENVIRONMENT,
  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,
  tracesSampler: (samplingContext) => {
    if (samplingContext.transactionContext.tags?.sampleRate === 'low') {
      return 0.1
    }
    if (samplingContext.transactionContext.tags?.sampleRate === 'medium') {
      return 0.2
    }
    return 1
  },
  beforeSend: (event, hint) => {
    if (SENTRY_ENV !== 'production') {
      console.error(hint.originalException || hint.syntheticException)
    }
    //Disable sending event to sentry from localhost
    return SENTRY_ENV === 'development' ? null : event
  },
  integrations: [],
})
